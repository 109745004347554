import { KEYS_PROFILES } from '~/enum/labels-articles'
import { KEYS_SECTIONS } from '~/enum/labels-sections'
import { TOGGLE } from '~/enum/toggles'
import { USER_PROFILE } from '~/enum/user'
import abtest from '~/mixins/abtest'
import chatbot from '~/mixins/chatbot'
import fetch from '~/mixins/fetch'
import header from '~/mixins/header'
import locale from '~/mixins/locale'

export default {
  mixins: [fetch, header, locale, chatbot, abtest],
  async fetch() {
    ;[this.responseBuyerSections, this.responseProducerSections, this.responseAffiliateSections] = await Promise.all([
      this.$section.getSuggestedSections(Object.values(KEYS_SECTIONS.BUYER)),
      this.$section.getSuggestedSections(Object.values(KEYS_SECTIONS.PRODUCER)),
      this.$section.getSuggestedSections(Object.values(KEYS_SECTIONS.AFFILIATE)),
    ])
    this.sections = this.responseBuyerSections?.filter(Boolean)
  },
  async mounted() {
    const { enabled } = await this.$toggle.retrieve(TOGGLE.HOME_SECTIONS_LIST)

    this.variantSections = enabled
  },
  methods: {
    changeProfile(profile) {
      const responseProfiles = {
        [USER_PROFILE.BUYER]: this.responseBuyerSections,
        [USER_PROFILE.PRODUCER]: this.responseProducerSections,
        [USER_PROFILE.AFFILIATE]: this.responseAffiliateSections,
      }

      this.userProfiles = this.userProfiles.map(userProfile => ({
        ...userProfile,
        isActive: userProfile.profile === profile,
      }))
      this.profile = profile
      this.sections = responseProfiles[profile]

      this.setVisible(this.isUserProfileBuyer)

      dataLayer.push({
        event: 'interaction',
        event_category: 'HelpCenter:home',
        event_action: 'Persona',
        event_label: profile,
      })
    },
  },
  computed: {
    isUserProfileBuyer() {
      return this.profile === USER_PROFILE.BUYER
    },
  },
  watch: {
    profile: {
      immediate: true,
      handler() {
        this.setVisible(this.isUserProfileBuyer)
      },
    },
  },
  data() {
    return {
      responseBuyerSections: {},
      responseProducerSections: {},
      responseAffiliateSections: {},
      responseSuggestedArticles: {},
      sections: [],
      userProfiles: Object.keys(KEYS_PROFILES).map((keyProfile, index) => ({
        label: this.$t(KEYS_PROFILES[keyProfile]),
        isActive: index === 0,
        profile: USER_PROFILE[keyProfile],
      })),
      profile: USER_PROFILE.BUYER,
      variantSections: true,
    }
  },
}
