import { mapGetters, mapActions } from 'vuex'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { hoverSupported } from '~/utils/hover'

export default {
  computed: {
    ...mapGetters('header', ['isSearchOpen', 'isSidebarOpen', 'isAlertInstability']),
  },

  methods: {
    ...mapActions('header', ['setSearchOpen', 'setSidebarOpen', 'setAlertInstability']),

    openSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(false)
    },

    closeSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(false)
    },

    toggleSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(!this.isSidebarOpen)
    },

    alertInstability(arrPayload) {
      this.setAlertInstability(arrPayload)
    },
  },

  watch: {
    isSearchOpen() {
      if (!hoverSupported()) return

      if (this.isSearchOpen) return disablePageScroll()

      return enablePageScroll()
    },

    isSidebarOpen() {
      if (!hoverSupported()) return

      if (this.isSidebarOpen) return disablePageScroll()

      return enablePageScroll()
    },
  },
}
