import isEmpty from 'lodash/isEmpty'
import { NAME_PAGE } from '~/enum/pages'
import { convertToSlug, mountURL as buildURL } from '~/utils/sharedHelpers'

export default {
  methods: {
    mountURL(page, id, title) {
      const PAGE_NAME = Object.values(NAME_PAGE).find(PAGE_NAME => PAGE_NAME === page)

      return buildURL(PAGE_NAME, id, title, this.$i18n.locale)
    },
    titleSlug(title) {
      return convertToSlug(title)
    },
    updateUrl(page, id, title) {
      const { query } = this.$route
      const querystring = !isEmpty(query) ? `?${new URLSearchParams(query).toString()}` : ''
      if (page && id && title && window) {
        window.history.replaceState({}, '', `${this.mountURL(page, id, title)}${querystring}`)
      }
    },
  },
}
