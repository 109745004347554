import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: 'primary',
      required: false,
    },
  },

  data() {
    return {
      isOpen: false,
      localeName: '',
    }
  },

  computed: {
    ...mapGetters('locale', ['getLocale', 'getLocales']),

    getLocaleName() {
      const locale = this.getLocales.find(locale => locale.code === this.getLocale)

      return locale.name
    },

    locales() {
      const locale = this.getLocale
      const locales = this.getLocales

      return locales[locale]
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.localeItems = this.$refs.items
      this.amoutOfLocaleItems = this.localeItems?.childElementCount
      this.firstLocaleItem = this.localeItems?.firstChild
    })

    this.localeName = this.getLocaleName
  },

  methods: {
    open() {
      this.isOpen = true

      this.localeItems.style.height = `${this.heightOfOpenItems}px`
    },

    close() {
      this.isOpen = false

      this.localeItems.style.height = `${this.heightOfCloseItems}px`
    },

    select(locale) {
      this.close()
      this.localeName = locale.name

      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_language',
        event_details: this.localeName,
      })

      setTimeout(() => {
        this.changeLocale(locale.code)
      }, 500)
    },

    changeLocale(locale) {
      const localeFrom = this.$route.params.locale
      const localeTo = locale
      let fullPathTo = `/${locale}`

      if (localeFrom) {
        const fullPathFrom = this.$route.fullPath
        fullPathTo = fullPathFrom.replace(new RegExp(`^\/${localeFrom}(.*)$`, 'gi'), `/${localeTo}$1`)
        if (fullPathTo === fullPathFrom) return
      }
      this.$axios.setHeader('Accept-Language', locale.toLowerCase())

      this.$router.push({ path: fullPathTo })
    },
  },
}
