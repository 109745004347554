import header from '~/mixins/header.js'
import debounce from 'lodash.debounce'

export default {
  mixins: [header],

  data() {
    return {
      previousScrollPositionY: 0,
      header: null,
      banner: null,
      unpinned: false,
      solid: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.header = document.querySelector('.header')
      this.banner = document.querySelector('#middleOfContent')

      let { y: previousScrollPositionY } = this.scrollPosition()
      this.previousScrollPositionY = previousScrollPositionY

      window.addEventListener('resize', this.resize, { capture: true, passive: true })
      window.addEventListener('scroll', this.scroll, { capture: true, passive: true })

      window.dispatchEvent(new Event('resize'))
      window.dispatchEvent(new Event('scroll'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    scroll(e) {
      this.scrollDebounce(e, this)
    },

    scrollDebounce: debounce((e, self) => {
      const { y: currentScrollPositionY } = self.scrollPosition()

      if (self.isScrollDown() && self.isOutsideSafetyMarginHeader()) self.unpinned = true
      if (self.isScrollUp()) {
        self.unpinned = false
        if (self.isOutsideSafetyMarginHeader()) self.solid = true
        else self.solid = false
      }

      self.previousScrollPositionY = currentScrollPositionY
    }, 16),

    scrollPosition() {
      let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollMinY = 0
      const scrollMaxY =
        window.scrollMaxY || document.documentElement.scrollHeight - document.documentElement.clientHeight

      if (scrollPositionY < scrollMinY) scrollPositionY = scrollMinY
      if (scrollPositionY > scrollMaxY) scrollPositionY = scrollMaxY

      return {
        y: scrollPositionY,
      }
    },

    isScrollDown() {
      const { y: currentScrollPositionY } = this.scrollPosition()
      const isScrollDown = this.previousScrollPositionY < currentScrollPositionY

      return isScrollDown
    },

    isScrollUp() {
      return !this.isScrollDown()
    },

    isOutsideSafetyMarginHeader() {
      if (!this.header || !this.banner) return

      const { height: headerHeight } = this.header.getBoundingClientRect()
      const { top: bannerOffsetTop } = this.banner.getBoundingClientRect()
      if (
        document.getElementsByClassName('header--unpinned').length > 0 &&
        document.getElementById('containerSuggestions') !== null
      ) {
        document.getElementById('containerSuggestions').style.display = 'none'
      }
      const headerSafetyMargin = headerHeight / 2

      return headerSafetyMargin < -bannerOffsetTop + 80
    },
  },
}
