export const getDeviceSize = () => {
  return window
    .getComputedStyle(document.querySelector('body'), '::before')
    .getPropertyValue('content')
    .replace(/"/g, '')
    .replace(/'/g, '')
}

export const getDeviceType = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return 'mobile'
  }

  return 'desktop'
}
