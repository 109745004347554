export default {
  props: {
    text: {
      type: String,
      required: false,
    },
    tag: {
      type: String,
      default: 'span',
      required: false,
    },
  },

  data() {
    return {
      htmlTag: this.tag,
    }
  },
}
