import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import cas from '~/mixins/cas'
import header from '~/mixins/header.js'
import user from '~/mixins/user'
import { getDeviceSize } from '~/utils/device'
import { convertNameAvatar } from '~/utils/sharedHelpers'

export default {
  mixins: [header, cas, user],
  data() {
    return {
      isLoading: false,
      localeName: '',
    }
  },
  computed: {
    ...mapGetters('locale', ['getLocale', 'getLocales']),
    ...mapGetters('casUser', ['userProfile', 'userToken']),

    userName() {
      return convertNameAvatar(this.userProfile?.name)
    },
    getLocaleName() {
      const locale = this.getLocales.find(locale => locale.code === this.getLocale)

      return locale.name
    },

    locales() {
      const locale = this.getLocale
      const locales = this.getLocales

      return locales[locale]
    },
    renderButtonLogin() {
      return !this.userToken
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))
    })
    this.localeName = this.getLocaleName
    const hotSelect = document.querySelector('hot-select')
    hotSelect?.addEventListener('change', e => this.select(e.detail))
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    ...mapActions('casUser', ['resetUserCas', 'setUserCas']),
    select(locale) {
      this.localeName = locale.text
      setTimeout(() => {
        this.changeLocale(locale.value)
      }, 500)
    },
    changeLocale(locale) {
      const localeFrom = this.$route.params.locale
      const localeTo = locale
      let fullPathTo = `/${locale}`

      if (localeFrom) {
        const fullPathFrom = this.$route.fullPath
        fullPathTo = fullPathFrom.replace(new RegExp(`^\/${localeFrom}(.*)$`, 'gi'), `/${localeTo}$1`)
        if (fullPathTo === fullPathFrom) return
      }

      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_language',
        event_details: this.localeName,
      })

      this.$axios.setHeader('Accept-Language', locale.toLowerCase())

      this.$router.push({ path: fullPathTo })
    },
    resize(e) {
      this.resizeDebounce(e, this)
    },

    loginCas() {
      this.isLoading = !this.isLoading
      this.signinRedirect()
    },

    resizeDebounce: debounce((e, self) => {
      const deviceSize = getDeviceSize()
      const isDesktop = /desktop(-m|-l|-xl)/i.test(deviceSize)
      if (isDesktop) self.closeSidebar(e)
    }, 100),

    closeSideBarAndNavigate(path) {
      this.closeSidebar(new Event('click'))

      if (path !== this.$route.path) this.$router.push({ path })
    },
    analyticsDetail(detail) {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_menu',
        event_details: detail,
      })
    },
    exitAccount() {
      this.analyticsDetail('logout')
      this.$cas.bootstrap.logout()
    },
  },
}
