const staging = {
  BUYER: {
    ID: '15412842449421',
  },
  PRODUCER: {
    ID: '15412867835789',
  },
  AFFILIATE: {
    ID: '15412867835789',
  },
}

const production = {
  BUYER: {
    ID: '25851725931533',
  },
  PRODUCER: {
    ID: '25851777677453',
  },
  AFFILIATE: {
    ID: '25851777677453',
  },
}
export const CATEGORIES = process.env.NODE_ENV === 'production' ? production : staging
