import { render, staticRenderFns } from "./SocialIcons.vue?vue&type=template&id=5b994fee&scoped=true"
import script from "./SocialIcons.js?vue&type=script&lang=js&external"
export * from "./SocialIcons.js?vue&type=script&lang=js&external"
import style0 from "./SocialIcons.scss?vue&type=style&index=0&id=5b994fee&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b994fee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default,HcLink: require('/drone/src/components/Molecules/Link/Link.vue').default})
