import { mapGetters } from 'vuex'
import { TOGGLE } from '~/enum/toggles'
import abtest from '~/mixins/abtest'
import fetch from '~/mixins/fetch'
import url from '~/mixins/url-structure'
import { convertNameAvatar } from '~/utils/sharedHelpers'

export default {
  props: {
    profile: {
      type: String,
    },
  },
  mixins: [fetch, url, abtest],
  async created() {
    await Promise.all([
      this.executeABTest({
        key: 'search-bar',
        testId: this.$config.environment === 'production' ? '65e5ddc3d96ac3088ff8e5de' : '65e5dc458313010ca3456277',
        condition: /^(pt-br|en|es)$/.test(this.$i18n.locale),
      }),
      this.$toggle.retrieve(TOGGLE.FAQGPT_SUMMARY),
    ])
  },
  methods: {
    onSearch() {
      this.convertABTest('search-bar')
    },
  },
  computed: {
    ...mapGetters('casUser', ['userToken', 'userProfile']),
    ...mapGetters('featureToggle', [TOGGLE.FAQGPT_SUMMARY]),
    nameUser: function () {
      return convertNameAvatar(this.userProfile?.name)
    },
    aiSearchBar: function () {
      return true
    },
  },
}
