import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('chatbot', ['open']),
    ...mapGetters('chatbot', [
      'canShowWelcomeMessage',
      'contactUsChatOpenToggle',
      'position',
      'size',
      'hasChatbotElement',
    ]),
  },
  watch: {
    open: {
      handler: function (open) {
        if (open) {
          this.setWelcomeMessageToggle(false)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('chatbot', [
      'initChatbotStore',
      'openChat',
      'closeChat',
      'toggleChat',
      'setWelcomeMessageToggle',
      'setVisible',
      'toggleVisibility',
    ]),
  },
  mounted() {
    this.initChatbotStore()
  },
}
