import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=dd841cbc&scoped=true"
import script from "./SearchBar.js?vue&type=script&lang=js&external"
export * from "./SearchBar.js?vue&type=script&lang=js&external"
import style0 from "./SearchBar.scss?vue&type=style&index=0&id=dd841cbc&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd841cbc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcInputCustom: require('/drone/src/components/Atoms/InputCustom/InputCustom.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
