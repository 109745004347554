export default {
  data() {
    return {
      year: null,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    analyticsDetail(detail) {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_footer',
        event_details: detail,
      })
    },
  },
  created() {
    this.year = new Date().getFullYear()
  },
}
