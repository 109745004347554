export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
    changeOption: {
      type: Function,
      required: false,
    },
    option: {
      type: String,
      required: false,
    },
  },
  methods: {
    callChangeOption() {
      this.changeOption(this.option)
    },
  },
}
