import { render, staticRenderFns } from "./ListCards.vue?vue&type=template&id=2c10d283&scoped=true"
import script from "./ListCards.js?vue&type=script&lang=js&external"
export * from "./ListCards.js?vue&type=script&lang=js&external"
import style0 from "./ListCards.scss?vue&type=style&index=0&id=2c10d283&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c10d283",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcCardSection: require('/drone/src/components/Molecules/CardSection/CardSection.vue').default,HcCard: require('/drone/src/components/Molecules/Card/Card.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
