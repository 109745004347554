import { render, staticRenderFns } from "./HeaderSidebarMenuMyRequests.vue?vue&type=template&id=39f0360e"
import script from "./HeaderSidebarMenuMyRequests.vue?vue&type=script&setup=true&lang=js"
export * from "./HeaderSidebarMenuMyRequests.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcDivider: require('/drone/src/components/Atoms/Divider/Divider.vue').default,HcHeaderSidebarMenuLink: require('/drone/src/components/Organisms/HeaderSidebar/HeaderSidebarMenu/HeaderSidebarMenuLink/HeaderSidebarMenuLink.vue').default})
