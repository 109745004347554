import { CATEGORIES } from '~/enum/categories'
import { NAME_PAGE } from '~/enum/pages'
import url from '~/mixins/url-structure'

export default {
  mixins: [url],
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: [Object, Array],
      default: [],
    },
    typeList: {
      type: String,
      default: 'articles',
    },
    isCardSection: {
      type: Boolean,
      default: false,
    },
    profile: String,
  },

  async fetch() {
    this.categories = await Promise.all([
      this.$categories.getSectionsCategories(this.categoriesObj.BUYER.ID),
      this.$categories.getSectionsCategories(this.categoriesObj.PRODUCER.ID),
      this.$categories.getSectionsCategories(this.categoriesObj.AFFILIATE.ID),
    ])
  },

  computed: {
    urlCategory() {
      const categoryId = this.categoriesObj[this.profile].ID
      const category = (this.categories || []).find(
        category => (category.id || '').toString() === (categoryId || '').toString()
      )

      if (!category) {
        return ''
      }

      return `${this.mountURL(NAME_PAGE.CATEGORIES, category.id, category.name)}?profile=${this.profile}`
    },
  },

  data() {
    return {
      categories: [],
      categoriesObj: CATEGORIES,
    }
  },

  methods: {
    gtmPush() {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_home_category_card',
        event_details: this.profile,
      })
    },
  },
}
