export default {
  computed: {
    isAwaitingResponse() {
      return this.$fetchState.pending
    },
  },

  watch: {
    '$route.query': '$fetch',
  },

  activated() {
    // Call fetch again if last fetch more than 3 minutes ago
    if (this.$fetchState.timestamp <= Date.now() - 180000) {
      this.$fetch()
    }
  },

  fetchOnServer: true,
}
