import { computed, useContext } from '@nuxtjs/composition-api'
import template from 'lodash/template'
import { MY_REQUESTS_URL } from './constants'


export default {
  __name: 'HeaderSidebarMenuMyRequests',
  setup(__props) {

const {
  route: {
    value: {
      params: { locale },
    },
  },
} = useContext()

const zendeskLocale = computed(
  () =>
    ({
      en: 'en-us',
      es: 'es-es',
    })[locale] ?? locale
)

const url = computed(() => template(MY_REQUESTS_URL)({ locale: zendeskLocale.value }))

return { __sfc: true,locale, zendeskLocale, url }
}

}