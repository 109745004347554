import { mapGetters } from 'vuex'
import { ITEM_LOCAL_STORAGE } from '~/enum/form/constants'
import { isEmptyObject } from '~/utils/object'
import { getFromLocalStorage } from '~/utils/sharedHelpers'

export default {
  data: () => ({
    transactions: [],
  }),
  computed: {
    ...mapGetters('casUser', ['userToken']),
  },
  methods: {
    getDataLocalStorage(property) {
      if (typeof window !== 'undefined') {
        const ITEMS = getFromLocalStorage(ITEM_LOCAL_STORAGE)
        if (!isEmptyObject(ITEMS)) return ITEMS[property]
      }

      return
    },
  },
}
