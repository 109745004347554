import { NAME_PAGE } from '~/enum/pages'
import url from '~/mixins/url-structure'

const optionsTypeList = {
  SECTIONS: 'sections',
  ARTICLES: 'articles',
}

export default {
  mixins: [url],
  props: {
    items: {
      type: Object,
      default: [],
    },
    typeList: {
      type: String,
      default: optionsTypeList.ARTICLES,
    },
  },
  computed: {
    assemblesUrlforAllArticles: function () {
      return this.mountURL(NAME_PAGE.SECTION, this.items.id, this.items.name)
    },

    renderViewAllArticles: function () {
      return [optionsTypeList.SECTIONS].includes(this.typeList)
    },
  },
  methods: {
    renderCard(items) {
      return items?.length > 0
    },
    gtmPush(title) {
      dataLayer.push({
        event: 'interaction',
        event_category: 'HelpCenter:home',
        event_action: 'Click:Article',
        event_label: title,
      })
    },
  },
  data() {
    return {
      namePage: NAME_PAGE.ARTICLE,
    }
  },
}
