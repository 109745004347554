export const KEYS_SECTIONS = {
  BUYER: {
    ACCOUNT_MANAGEMENT: 'gerenciando-sua-conta',
    BUY_AND_NEED_HELP: 'quero-comprar-e-preciso-de-ajuda',
    ACCOUNT_AND_ACCESS: 'minha-conta-e-acesso',
    PRODUCT_QUESTIONS: 'duvidas-sobre-o-produto-que-comprei',
    INFO_ABOUT_SUBSCRIPTION_PAYMENT: 'assinatura-e-pagamentos-recorrentes',
    CANCELLATION_REFUND_UNIDENTIFIED_BILLING: 'cancelamento-reembolso-e-cobranca-nao-identificada',
  },
  PRODUCER: {
    ACCOUNT_MANAGEMENT: 'gerenciando-sua-conta',
    MY_HOTMART_ACCOUNT: 'minha-conta-hotmart',
    MY_AFFILIATE_PROGRAM: 'meu-programa-de-afiliados',
    PRODUCT_REGISTRATION_MANAGEMENT: 'cadastro-e-gestao-de-produtos',
    TAX_INFORMATION: 'informacoes-fiscais',
    MY_WALLET: 'minha-carteira',
  },
  AFFILIATE: {
    ACCOUNT_MANAGEMENT: 'gerenciando-sua-conta',
    MY_HOTMART_ACCOUNT: 'minha-conta-hotmart',
    MY_AFFILIATIONS: 'minhas-afiliacoes',
    TAX_INFORMATION: 'informacoes-fiscais',
    MY_WALLET: 'minha-carteira',
    COMISSION_TRACKING: 'comissao-e-rastreamento',
  },
}

export const DEFAULT_SECTIONS = {
  BUYER: {
    ACCOUNT_MANAGEMENT: 'gerenciando-sua-conta',
  },
  PRODUCER: {
    KNOW_HOTMART: 'conheca-a-hotmart',
  },
  AFFILIATE: {
    REPORTS_AND_SERVICE: 'denuncias-e-atendimento',
  },
}
