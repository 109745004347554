import { onMounted, ref, useContext } from '@nuxtjs/composition-api'


export default {
  __name: 'HeaderSidebarMenu',
  setup(__props) {

const { $checkLogged } = useContext()
const shouldRender = ref(false)

onMounted(() => {
  shouldRender.value = $checkLogged()
})

return { __sfc: true,$checkLogged, shouldRender }
}

}