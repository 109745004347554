import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('abtest', ['abTests']),
  },
  methods: {
    ...mapActions('abtest', ['executeABTest', 'convertABTest']),
    checkABTestVariant(key, variant) {
      return this.abTests?.[key]?.variant === variant
    },
  },
}
