export default {
  methods: {
    setArticleAsVisited(articleId) {
      if (!process.client) return
      const articleHistory = new Set(JSON.parse(sessionStorage.getItem('ARTICLE_HISTORY')) || [])
      articleHistory.add(articleId)
      sessionStorage.setItem('ARTICLE_HISTORY', JSON.stringify(Array.from(articleHistory)))
    },
    isVisitedArticle(articleId) {
      if (!process.client) return
      return JSON.parse(sessionStorage.getItem('ARTICLE_HISTORY') || '[]').includes(articleId.toString())
    },
  },
}
