import { NAME_PAGE } from '~/enum/pages'
import articleHistory from '~/mixins/article-history'
import url from '~/mixins/url-structure'

export default {
  mixins: [url, articleHistory],
  data() {
    return {
      namePage: NAME_PAGE.CONTACT_US,
    }
  },
  methods: {
    sendToHelp() {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_article_contact_us',
        event_details: this.namePage,
      })
      this.$router.push(this.mountURL(this.namePage))
      this.setArticleAsVisited(this.$route.params.id)
    },
  },
  computed: {
    shouldRender: function () {
      return /^locale-article-id-slug$/.test(this.$route.name)
    },
  },
}
