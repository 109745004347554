import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=88e7a4a0&scoped=true"
import script from "./Home.js?vue&type=script&lang=js&external"
export * from "./Home.js?vue&type=script&lang=js&external"
import style0 from "./Home.scss?vue&type=style&index=0&id=88e7a4a0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e7a4a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcLoading: require('/drone/src/components/Organisms/Loading/Loading.vue').default,HcHeader: require('/drone/src/components/Organisms/Header/Header.vue').default,HcHeaderSidebar: require('/drone/src/components/Organisms/HeaderSidebar/HeaderSidebar.vue').default,HcWelcomeBanner: require('/drone/src/components/Organisms/WelcomeBanner/WelcomeBanner.vue').default,HcNavItem: require('/drone/src/components/Atoms/NavItem/NavItem.vue').default,HcNav: require('/drone/src/components/Atoms/Nav/Nav.vue').default,HcListCards: require('/drone/src/components/Organisms/ListCards/ListCards.vue').default,HcSendHelp: require('/drone/src/components/Molecules/SendHelp/SendHelp.vue').default,HcChatbotWelcomeMessage: require('/drone/src/components/Molecules/ChatbotWelcomeMessage/ChatbotWelcomeMessage.vue').default,HcFooter: require('/drone/src/components/Organisms/Footer/Footer.vue').default})
