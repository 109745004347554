import merge from 'lodash.merge'
import { LANGUAGE } from '~/enum/language'
import runtime from '../config/runtime'

const defaultOptions = {
  lang: '',
  url: '',
  title: '',
  titleTemplate: '',
  description: '',
  canonical: '',
  author: 'Hotmart',
  images: {
    general: 'images/share--general.jpg',
    facebook: 'images/share--facebook.jpg',
    twitter: 'images/share--twitter.jpg',
  },
  facebook: {
    siteName: 'Hotmart',
    title: '',
    description: '',
  },
  twitter: {
    site: '@hotmart',
    creator: '@hotmart',
    title: '',
    description: '',
  },
}

export const head = options => {
  options = merge(defaultOptions, options)

  const dir = options.lang === LANGUAGE.ARABIC ? 'rtl' : 'ltr'
  const script = [
    {
      type: 'application/ld+json',
      json: {
        name: 'Hotmart - Help Center',
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://help.hotmart.com/',
        author: {
          '@type': 'Company',
          name: 'Hotmart',
        },
      },
    },
    {
      type: 'text/javascript',
      src: 'https://survey.survicate.com/workspaces/9cd0d9cb3823f32f6cb3492eb6e7d1e4/web_surveys.js',
      async: true,
    },
  ]

  const robots =
    runtime.publicRuntimeConfig.environment === 'development'
      ? { name: 'robots', content: 'noindex' }
      : options.robots || {}
  const googlebot =
    runtime.publicRuntimeConfig.environment === 'development' ? { name: 'googlebot', content: 'noindex' } : {}

  const head = {
    htmlAttrs: {
      lang: options.lang,
      dir,
      class: `application--is-${dir}`,
    },

    title: options.title,

    titleTemplate: options.titleTemplate,

    link: [{ hid: 'canonical', rel: 'canonical', href: (options.canonical || '').replace('www.', '') }],

    meta: [
      robots,
      googlebot,

      // Share - General
      { hid: 'url', itemprop: 'url', content: options.url },
      { hid: 'description', name: 'description', content: options.description },
      { hid: 'image', itemprop: 'image', content: `${options.images.general}` },

      // Share - Facebook
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:title', property: 'og:title', content: options.title },
      { hid: 'og:description', property: 'og:description', content: options.description },
      { hid: 'og:image', property: 'og:image', content: `${options.images.facebook}` },
      { hid: 'og:site_name', property: 'og:site_name', content: options.title },
      { hid: 'og:locale', property: 'og:locale', content: options.lang },
      { hid: 'og:url', property: 'og:url', content: options.url },

      // Share - Twitter
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
      { hid: 'twitter:site', name: 'twitter:site', content: '@hotmart' },
      { hid: 'twitter:creator', name: 'twitter:creator', content: '@hotmart' },
      { hid: 'twitter:url', name: 'twitter:url', content: options.url },
      { hid: 'twitter:title', name: 'twitter:title', content: options.title },
      { hid: 'twitter:description', name: 'twitter:description', content: options.description },
      { hid: 'twitter:image', name: 'twitter:image', content: `${options.images.twitter}` },
    ],
    script,
  }
  if (options.alternates) {
    alternates(options.alternates, options.canonical, options.lang).forEach(alternate => head.link.push(alternate))
  }

  return head
}
const alternates = (data, url, lang) => {
  const availableLocales = Object.values(LANGUAGE)
  const rel = 'alternate'

  if (url.match(/article|categories|section/g)) {
    const href = url.replace('www.', '')
    const hreflang = lang

    const alternate = { rel, href, hreflang: hreflang === 'pt-br' ? 'pt-BR' : hreflang }

    if (hreflang === 'pt-br') {
      const alternateDefault = { rel, href, hreflang: 'x-default' }

      return [alternate, alternateDefault]
    }

    return [alternate]
  } else {
    const portugueseLang = 'pt-br'
    const defaultHrefLang = 'x-default'

    return availableLocales.map(locale => ({
      rel,
      href: locale === defaultHrefLang ? data[portugueseLang] : data[locale],
      hreflang: locale === portugueseLang ? 'pt-BR' : locale,
    }))
  }
}
