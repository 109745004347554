import { NAME_PAGE } from '~/enum/pages'
import url from '~/mixins/url-structure'

const optionsTypeList = {
  SECTIONS: 'sections',
  ARTICLES: 'articles',
}

export default {
  mixins: [url],
  props: {
    items: {
      type: Object,
      default: [],
    },
    typeList: {
      type: String,
      default: optionsTypeList.ARTICLES,
    },
    profile: {
      type: String,
      default: '',
    },
  },
  computed: {
    assemblesUrlforAllArticles: function () {
      const { id, name } = this.items
      return `${this.mountURL(NAME_PAGE.SECTION, id, name)}?profile=${this.profile}`
    },
  },
  methods: {
    renderCard(items) {
      return items?.length > 0
    },
    gtmPush() {
      const title = this.items.name

      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_home_section_card',
        event_details: title,
      })
    },
  },
  data() {
    return {
      namePage: NAME_PAGE.ARTICLE,
    }
  },
}
