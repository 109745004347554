export const KEYS_ARTICLES = {
  BUYER: {
    YOUR_PURCHASE: 'home-group-buyer-purchase',
    CANCELLATION_REFUND: 'home-group-buyer-refund',
    ACCOUNT_REGISTRATION: 'home-group-buyer-registration',
    ACCOUNT: 'home-group-buyer-account',
    PRODUCT_QUESTIONS: 'home-group-buyer-product-questions',
    PAYMENT_OPTIONS: 'home-group-buyer-payment-options',
  },
  PRODUCER: {
    ACCOUNT: 'home-group-creator-account',
    PRODUCTS: 'home-group-creator-products',
    FINANCIAL: 'home-group-creator-financial',
    MANAGE_ACCOUNT: 'home-group-creator-manage-account',
    CLUB: 'home-group-creator-club',
    TAX: 'home-group-creator-tax',
  },
  AFFILIATE: {
    AFFILIATIONS: 'home-group-affiliations',
    ACCESS: 'home-group-affiliate-access',
    ACCOUNT: 'home-group-affiliate-account',
    FINANCIAL: 'home-group-affiliate-financial',
    HOTLINK: 'home-group-affiliate-hotlink',
    TAX: 'home-group-affiliate-tax',
  },
}

export const TILES_CARDS = {
  BUYER: {
    YOUR_PURCHASE: 'featuredArticles.cardPurchase',
    CANCELLATION_REFUND: 'featuredArticles.cardRefund',
    ACCOUNT_REGISTRATION: 'featuredArticles.cardAccount',
    ACCOUNT: 'featuredArticles.cardAccountAndChange',
    PRODUCT_QUESTIONS: 'featuredArticles.cardProducts',
    PAYMENT_OPTIONS: 'featuredArticles.cardFinancial',
  },
  PRODUCER: {
    ACCOUNT: 'featuredArticles.cardAccountAndChange',
    PRODUCTS: 'featuredArticles.cardActivation',
    FINANCIAL: 'featuredArticles.cardIncomeWithdrawal',
    MANAGE_ACCOUNT: 'featuredArticles.cardAccountManagement',
    CLUB: 'featuredArticles.cardClubConfiguration',
    TAX: 'featuredArticles.cardTaxDoubt',
  },
  AFFILIATE: {
    AFFILIATIONS: 'featuredArticles.cardAffiliateDoubt',
    ACCESS: 'featuredArticles.cardAccountAccess',
    ACCOUNT: 'featuredArticles.cardAccountAndChange',
    FINANCIAL: 'featuredArticles.cardIncomeWithdrawal',
    HOTLINK: 'featuredArticles.cardHotlinkConfiguration',
    TAX: 'featuredArticles.cardTaxDoubt',
  },
}

export const KEYS_PROFILES = {
  BUYER: 'userProfile.buyer',
  PRODUCER: 'userProfile.producer',
  AFFILIATE: 'userProfile.affiliate',
}
