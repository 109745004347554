import chatbot from '~/mixins/chatbot'
export default {
  name: 'Speech',
  mixins: [chatbot],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    handleClose: {
      type: Function,
      required: true,
    },
    handleOpen: {
      type: Function,
      required: true,
    },
    orientation: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: String,
    },
    arrowPosition: {
      type: String,
      default: 'right-bottom',
    },
  },
}
