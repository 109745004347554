import { render, staticRenderFns } from "./SendHelp.vue?vue&type=template&id=3e55c33d&scoped=true"
import script from "./SendHelp.js?vue&type=script&lang=js&external"
export * from "./SendHelp.js?vue&type=script&lang=js&external"
import style0 from "./SendHelp.scss?vue&type=style&index=0&id=3e55c33d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e55c33d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
