import { head } from '@/utils/head'
import { alternatives, availableLocale } from '@/utils/sharedHelpers'
import { mapGetters } from 'vuex'

export default {
  validate({ params }) {
    const { locale } = params
    if (availableLocale(locale)) return true
  },

  computed: {
    ...mapGetters('casUser', ['userToken']),
  },

  mounted() {
    dataLayer.push({
      event: 'pageview',
      section: 'Help Center',
      subsection1: 'Home',
      subsection2: window.location.href,
      language: this.$i18n.locale,
      logged: this.$checkLogged(),
    })

    if (this.$checkLogged()) {
      const userData = this.$store.getters['casUser/userProfile']

      userData?.id &&
        this.$sentry?.setUser?.({
          id: userData.id,
          email: userData.email,
        })
    }
  },
  head() {
    const { fullPath } = this.$route
    const lang = this.$i18n.locale
    const canonical = `${this.$config.baseUrl}/${this.$i18n.locale}`
    const alternates = alternatives(fullPath)
    const title = this.$t('seo.title')
    const description = this.$t('seo.description')
    const url = ` ${this.$i18n.locale}`
    const robots = { name: 'robots', content: 'all' }

    return head({
      lang,
      canonical,
      alternates,
      title,
      description,
      url,
      robots,
    })
  },
}
