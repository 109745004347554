import { render, staticRenderFns } from "./CardSection.vue?vue&type=template&id=5e4f641c&scoped=true"
import script from "./CardSection.js?vue&type=script&lang=js&external"
export * from "./CardSection.js?vue&type=script&lang=js&external"
import style0 from "./CardSection.scss?vue&type=style&index=0&id=5e4f641c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4f641c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default})
