import { render, staticRenderFns } from "./WelcomeBanner.vue?vue&type=template&id=7243f028&scoped=true"
import script from "./WelcomeBanner.js?vue&type=script&lang=js&external"
export * from "./WelcomeBanner.js?vue&type=script&lang=js&external"
import style0 from "./WelcomeBanner.scss?vue&type=style&index=0&id=7243f028&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7243f028",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcSearchBar: require('/drone/src/components/Molecules/SearchBar/SearchBar.vue').default})
