import { mapGetters } from 'vuex'
import { NAME_PAGE } from '~/enum/pages'
import { TOGGLE } from '~/enum/toggles'
import { USER_PROFILE } from '~/enum/user'
import url from '~/mixins/url-structure'

export default {
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    isInternalPage: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
    },
    aiSearchBar: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [url],
  methods: {
    async searchArticle() {
      if (this.searchValue.length >= 3 && this.searchValue.trim()) {
        this.sendAnalyticsSearchTerm(this.searchValue)
        this.$emit('search', this.searchValue)

        return this.$router.push({
          path: `/${this.$i18n.locale}/${NAME_PAGE.SEARCH}`,
          query: { query: this.searchValue.trim(), page: 1 },
        })
      }
    },
    sendAnalyticsSearchTerm(term) {
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'view_search_results',
        event_details: term.toLowerCase(),
      })
    },
    isHomeSearch(showHeader) {
      return this.$route.name !== 'locale-search' && this.$route.name === 'locale' && !showHeader
    },
    isNotHomeSearch(showHeader, isInternalPage) {
      const isLocaleArticlePage = /^locale-(article|categories)-id-slug$/.test(this.$route.name)
      const shouldShowHeader = showHeader && this.$route.name !== 'locale-search' && this.$route.name !== 'locale'

      return isInternalPage ? isLocaleArticlePage && shouldShowHeader : !isLocaleArticlePage && shouldShowHeader
    },
    getPlaceholder() {
      const placeholders = {
        [USER_PROFILE.BUYER]: this.$t('headerPrimary.input.placeholder.buyer'),
        [USER_PROFILE.PRODUCER]: this.$t('headerPrimary.input.placeholder.producer'),
        [USER_PROFILE.AFFILIATE]: this.$t('headerPrimary.input.placeholder.affiliate'),
      }

      return placeholders[this.profile]
    },
  },
  computed: {
    ...mapGetters('featureToggle', [TOGGLE.FAQGPT_SUMMARY]),
    icon() {
      return this.aiSearchBar ? undefined : 'search'
    },
  },
  data() {
    return {
      searchValue: '',
    }
  },
}
