export default {
  __name: 'HeaderSidebarMenuLink',
  props: {
  href: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  dataLayer: {
    type: Object,
    required: true,
  },
},
  emits: ['click'],
  setup(__props, { emit }) {

const props = __props;





function handleClick(event) {
  emit('click', event)
  handleDataLayer()
}

function handleDataLayer() {
  if (!props.dataLayer) return

  window.dataLayer.push({
    event: 'custom_event',
    custom_event_name: 'click_menu',
    ...props.dataLayer,
  })
}

return { __sfc: true,props, emit, handleClick, handleDataLayer }
}

}